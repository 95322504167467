import React from 'react'
import { Link } from 'gatsby'
import { Grid, Button, Icon } from 'semantic-ui-react'

class Pagination extends React.Component {
  render() {
    const { numPages, currentPage, slug } = this.props.context
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const isNotPaginated = isFirst & isLast

    const prevPageNum = currentPage - 1 === 1 ? `` : currentPage - 1
    const nextPageNum = currentPage + 1

    const pathPrefix = typeof slug === 'string' ? `/tag/${slug}` : ''
    const prevPageLink = isFirst ? '' : `${pathPrefix}/${prevPageNum}/`
    const nextPageLink = isLast ? '' : `${pathPrefix}/${nextPageNum}/`

    return (
      <div id="page_indicator">
        <Button animated to={prevPageLink} as={Link} color='blue' disabled={isFirst}>
          <Button.Content visible>Previous</Button.Content>
          <Button.Content hidden>
            <Icon name='arrow left' />
          </Button.Content>
        </Button>
        <Button animated to={nextPageLink} as={Link} color='blue' disabled={isLast}>
          <Button.Content visible>Next</Button.Content>
          <Button.Content hidden>
            <Icon name='arrow right' />
          </Button.Content>
        </Button>
      </div>
    )
  }
}

export default Pagination
