import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-plugin-image'
import { Grid, Segment } from 'semantic-ui-react'
import TagList from '../components/TagList'
import PostDetails from '../components/PostDetails'

const Card = ({
  slug,
  title,
  featured,
  publishDate,
  body,
  categories,
  tags,
  body: {
    childMarkdownRemark: { timeToRead },
  },
  ...props
}) => {
  const sizes = featured ? { mobile: 12 } : { mobile: 12, tablet: 6, computer: 4 }
  let categoryTitle = categories && categories[0].title
  let categoryColor = categories && categories[0].color

  return (
    <Grid.Column {...sizes} className="card">
      <Link to={`/${slug}/`}>
        <div className={`card-header ${categoryTitle.toLowerCase()}`} />
        <div className={`card-content ${categoryTitle.toLowerCase()}`}>
          <h4>{categories && categories[0].title}</h4>
          <h2>{title}</h2>
          <div className="card-meta">
            <PostDetails publishDate={publishDate} timeToRead={timeToRead} />
            <div
              className="card-excerpt"
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.excerpt,
              }}
            />
          </div>
        {tags && <TagList tags={tags} />}
        </div>
      </Link>
    </Grid.Column>
  )
}

export default Card
