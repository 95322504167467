import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import { Button } from 'semantic-ui-react'
import { uniqBy, orderBy } from 'lodash'

const CategoryMenuComponent = ({ categories, active }) => (
  <div id="category-menu">
    <h2>Homey Learn is an education resource for homeowners & HOA board members.</h2>
    <div id="category-menu-items">
      { categories.map(category => (
        <Button
          key={category.id}
          color={category.color}
          as={Link}
          to={`/category/${category.slug}/`}
        >
          {category.title}
        </Button>
      ))}
    </div>
  </div>
)

const getCategoriesFromPosts = posts => {
  const categoryArray = []
  posts.map(post => (
    post.node.categories && post.node.categories.map(category => (
      categoryArray.push(category)
    ))
  ))

  // Dedupe and sort the categories
  return sortCategories(uniqBy(categoryArray, 'title'), 'asc')
}

const sortCategories = (categories, order) => {
  return orderBy(categories, ['order'], [order]);
}

// (1.) Query for posts
export default (props) => (
  <StaticQuery query={graphql`
      query {
        allContentfulPost(
          sort: { fields: [publishDate], order: DESC }
          limit: 1000
        ) {
          edges {
            node {
              title
              id
              slug
              categories {
                title
                id
                slug
                color
                order
              }
            }
          }
        }
      }
    `}

    render={data => {
      // (1.) Grab any props (currentCategory etc)
      const { activeCategory } = props;

      // (2.) Create dedup'd list of categories
      const categories = getCategoriesFromPosts(data.allContentfulPost.edges);

      // (3.) Render it
      return (
        <CategoryMenuComponent
          categories={categories}
          active={activeCategory}
        />
      )
    }}
  />
)
