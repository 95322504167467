import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'

const CardList = props => {
  return (
    <div className="card_list">
      <Grid container>
        {props.children}
      </Grid>
    </div>
  )
}

export default CardList
