import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import CategoryMenu from '../components/CategoryMenu'
import Card from '../components/Card'
import Subscribe from '../components/Subscribe'
import Helmet from 'react-helmet'
import MaxWidth from '../components/MaxWidth'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import { Grid } from 'semantic-ui-react'
import { filter } from 'lodash'

const Index = ({ data, pageContext }) => {
  const posts = data.allContentfulPost.edges
  const metaData = data.allContentfulMetadata.edges
  const { currentPage } = pageContext
  const isFirstPage = currentPage === 1

  const findMetaPropertyValue = prop => {
    var md = filter(metaData, i => {
      return i.node.key === prop
    })
    return md[0].node.value
  }

  return (
    <Layout pageID="home">
      <SEO />
      {!isFirstPage && (
        <Helmet>
          <title>{`${config.siteTitle} - Page ${currentPage}`}</title>
        </Helmet>
      )}
      <section id="page-categories">
        <MaxWidth>
          <CategoryMenu context={pageContext} />
        </MaxWidth>
      </section>
      <section id="page-content">
        <MaxWidth>
          {isFirstPage ? (
            <CardList>
              {posts.map(({ node: post }) => (
                <Card key={post.id} {...post} />
              ))}
            </CardList>
          ) : (
            <CardList>
              {posts.map(({ node: post }) => (
                <Card key={post.id} {...post} />
              ))}
            </CardList>
          )}
          <Pagination context={pageContext} />
        </MaxWidth>
      </section>
      <section className="page-subscribe">
        <Subscribe />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulMetadata {
      edges {
        node {
          key
          value
        }
      }
    }

    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          id
          slug
          categories {
            title
            id
            slug
            color
          }
          tags {
            title
            id
            slug
          }
          publishDate(formatString: "MMMM DD, YYYY")
          body {
            childMarkdownRemark {
              timeToRead
              html
              excerpt(pruneLength: 200)
            }
          }
        }
      }
    }
  }
`

export default Index
